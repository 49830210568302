import { Container } from "@mui/material";
import Faq from "../new-landing/Faq";
import LayoutV2 from "../layout/LayoutV2";
import React from "react";
import CheckoutHero from "../new-landing/CheckoutHero";
import PricingStep from "../new-landing/PricingStep";
import CheckoutPricing from "../new-landing/CheckoutPricing";
import CheckoutGetStarted from "../new-landing/CheckoutGetStarted";

const CheckoutScreen = () => {
  return (
    <LayoutV2
      hideNavLinks
      hideFooterNav
      hideFooterResources
      child={
        <React.Fragment>
          <Container sx={{}}>
            <CheckoutHero />
            <CheckoutPricing />
            <PricingStep />
            <CheckoutGetStarted />
            <Faq />
          </Container>
        </React.Fragment>
      }
    />
  );
};

export default CheckoutScreen;
