import { Box, Button, Stack, Typography } from "@mui/material";
import { AppColours } from "../../helpers/colors";
import playstoreApp from "../../assets/new-landing/googleplay-app-latest.png";

const Devices = () => {
  return (
    <Box
      component="section"
      marginTop={6}
      sx={{
        textAlign: "center",
      }}
    >
      <Typography
        variant="h6"
        component="h2"
        sx={{
          color: "#996000",
          fontWeight: "bold",
          marginTop: "6rem",
          textAlign: "center",
          backgroundColor: "#FFF6E5",
          width: "fit-content",
          margin: "auto",
          padding: "1.2rem 3rem",
          borderRadius: "1rem",
        }}
      >
        STEP TWO
      </Typography>

      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          marginY: "2rem",
        }}
      >
        Download and Install Shareduled Business On Your Device!
      </Typography>

      <Stack spacing={4} marginY={3}>
        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          This is where the magic happens.
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          Visit Google PlayStore and Apple App Store, search "Shareduled",
          download and install the Shareduled Business app and start reaping all
          the benefits immediately.
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          (PS: Download link coming soon ...)
        </Typography>
      </Stack>
      <Box
        component="img"
        loading="lazy"
        src={playstoreApp}
        alt="A business owner relaxing"
        sx={{ borderRadius: 5 }}
      />

      <Box
        sx={{
          display: "none",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <Button
          variant="contained"
          disabled
          sx={{
            width: "26rem",
            marginBottom: "1rem",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 0",
          }}
        >
          DOWNLOAD SHAREDULED BUSINESS
        </Button>
      </Box>
    </Box>
  );
};

export default Devices;
