import LayoutV2 from "../layout/LayoutV2";
import UserGuide from "../new-landing/UserGuide";

const UserGuideScreen = () => {
  return (
    <LayoutV2
      hideFooterNav
      hideFooterResources
      hideNavLinks
      child={<UserGuide />}
    />
  );
};

export default UserGuideScreen;
