import { Box, Stack, Typography } from "@mui/material";

const PricingStep = () => {
  return (
    <Box>
      <Stack spacing={4} sx={{}}>
        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
          }}
        >
          Immediately you sign up for the{" "}
          <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
            lifetime
          </Box>{" "}
          offer (or any other offer that's right for you)...
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
          }}
        >
          You'll be redirected to a secure payment page to pay for your package.
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
          }}
        >
          Once you’re done with that…
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
          }}
        >
          You will immediately receive a confirmation email with your unique
          access code and any other necessary detail.
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
          }}
        >
          That’s it.
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
          }}
        >
          You’re in automatically!
        </Typography>
      </Stack>
    </Box>
  );
};

export default PricingStep;
