import { Box, Stack, Typography } from "@mui/material";

const WhatNext = () => {
  return (
    <Box
      component="section"
      marginTop={6}
      sx={{
        textAlign: "center",
      }}
    >
      <Stack spacing={3}>
        <Typography
          variant="h4"
          component="h3"
          sx={{
            fontWeight: "bold",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          What's Next?
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          You'll receive a welcome email which contains a unique code to your
          account with more information about our community and resources.
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          Our support team is here to help; please don't hesitate to reach out.
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          See you in the community.
        </Typography>

        <Typography
          sx={{
            fontWeight: "bold",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          Joseph O. (Founder of Shareduled)
        </Typography>
      </Stack>
    </Box>
  );
};

export default WhatNext;
