import { Box, Button, Typography } from "@mui/material";
import { AppColours } from "../../helpers/colors";
import { useNavigate } from "react-router-dom";

const CheckoutGetStarted = () => {
  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: "#FFFBF5",
        padding: "4rem 3rem",
        marginTop: "4rem",
      }}
    >
      {/* Head text */}
      <Typography
        variant="h4"
        component="h3"
        sx={{
          textAlign: { xs: "left", md: "center" },
          fontWeight: "bold",
          color: "#333",
          marginBottom: "1rem",
        }}
      >
        Get Started with Shareduled in Easy Steps
      </Typography>
      <Typography
        variant="h6"
        component="p"
        sx={{
          textAlign: { xs: "left", md: "center" },
          color: "#8898AA",
          marginBottom: "1rem",
        }}
      >
        We're made it easy for you to join Shareduled and enjoy the benefits
        immediately.
      </Typography>

      <Typography
        variant="h6"
        component="p"
        sx={{
          textAlign: { xs: "left", md: "center" },
          color: "#8898AA",
          marginBottom: "1rem",
        }}
      >
        Follow the quick guide to set up your account
      </Typography>

      {/* Steps */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          component="h4"
          sx={{
            textAlign: { xs: "left", md: "center" },
            fontWeight: "bold",
            color: "#333",
            marginTop: "3rem",
          }}
        >
          Business Owners
        </Typography>
        <Typography
          variant="h6"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
            marginBottom: "1rem",
          }}
        >
          Just 4 steps to register your business, list your services and start
          reaching more customers.
        </Typography>

        <Button
          variant="contained"
          onClick={() => {
            navigate("/business-guide");
          }}
          sx={{
            width: { xs: "18rem", sm: "26rem" },
            marginBottom: "1rem",
            justifyItems: "center",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 0",
          }}
        >
          View Business Setup Guide
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          component="h4"
          sx={{
            textAlign: { xs: "left", md: "center" },
            fontWeight: "bold",
            color: "#333",
            marginTop: "3rem",
          }}
        >
          Regular Users
        </Typography>
        <Typography
          variant="h6"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
            marginBottom: "1rem",
          }}
        >
          Just 3 steps to open an account and book appointments in real time
          without wasting time looking for the right business.
        </Typography>

        <Button
          variant="contained"
          onClick={() => {
            navigate("/user-guide");
          }}
          sx={{
            width: { xs: "18rem", sm: "26rem" },
            marginBottom: "1rem",
            justifyItems: "center",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 0",
          }}
        >
          View User Setup Guide
        </Button>
      </Box>
    </Box>
  );
};

export default CheckoutGetStarted;
