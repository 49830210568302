import { Box, Container, Stack, Typography } from "@mui/material";

const CheckoutHero = () => {
  return (
    <Container>
      <Box textAlign="center" marginTop={8} id="/">
        <Box component="div">
          <Typography
            variant="body1"
            component="p"
            sx={{
              color: "red",
              fontWeight: "bold",
              textAlign: { xs: "left", md: "center" },
            }}
          >
            Warning: LIMITED SLOTS AVAILABLE
          </Typography>

          <Typography
            variant="h3"
            component="h3"
            fontWeight={700}
            marginTop={4}
            sx={{
              textAlign: { xs: "left", md: "center" },
            }}
          >
            Discover{" "}
            <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
              “Shareduled's”
            </Box>{" "}
            Best Deals For Real-Time Appointment Booking For Your Business
          </Typography>
        </Box>
      </Box>

      <Stack
        spacing={3}
        sx={{
          mt: { xs: "1.5rem", sm: "2rem", md: "3rem" },
        }}
      >
        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
          }}
        >
          Hey there
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
          }}
        >
          {" "}
          Below is the best deal you'll{" "}
          <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
            ever
          </Box>{" "}
          see for scheduling real-time bookings with{" "}
          <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
            Shareduled
          </Box>{" "}
          .
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            textAlign: { xs: "left", md: "center" },
            color: "#8898AA",
          }}
        >
          {" "}
          Choose the{" "}
          <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
            lifetime
          </Box>{" "}
          offer (or any other offer that’s right for you) below... and secure
          your spot today before it’s too late!
        </Typography>
      </Stack>
    </Container>
  );
};

export default CheckoutHero;
