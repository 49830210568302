import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Fade,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";
import Backdrop from "@mui/material/Backdrop";
import emailIcon from "../../assets/new-landing/email.png";
import firstnameIcon from "../../assets/new-landing/firstname.png";
import countryIcon from "../../assets/new-landing/country.png";
import code from "../../assets/new-landing/code.png";
import axios from "axios";

interface SubscriptionResponse {
  link: string;
  identifier: string;
  success: boolean;
  existing: boolean;
  message: string;
}

type Plan = {
  title: string;
  description: string;
  price: string;
  discount: string;
  note: string;
  key: "Lifetime" | "Monthly" | "Yearly";
  buttonText: string;
};

function CheckoutPricing() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [buttonText, setButtonText] = React.useState("Get lifetime access now");
  // Combined state for form inputs and validation
  const [formState, setFormState] = React.useState({
    email: "",
    firstName: "",
    isAdult: false,
    emailError: "",
    firstNameError: "",
    verificationCode: "",
    verificationCodeError: "",
    country: "Canada",
    ageRange: "18_and_above",
    plan: "Lifetime",
  });

  const [, setResponseMessage] = React.useState<string | null>(null);
  const [responseErrorMessage, setResponseErrorMessage] = React.useState<
    string | null
  >(null);
  const [countdown, setCountdown] = React.useState(300); // 5 minutes in seconds
  const [identifier, setIdentifier] = React.useState<string | null>(null); // State to store UUID

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("API call initiated"); // Debugging log
    try {
      const payload = {
        first_name: formState.firstName,
        email: formState.email,
        country: formState.country,
        ageRange: formState.ageRange,
        plan: formState.plan,
      };

      const headers = {
        "Content-Type": "application/json",
        "X-Shareduled-Platform": "PUBLIC",
      };

      console.log("Request Payload:", payload); // Debugging log
      console.log("Request Headers:", headers); // Debugging log

      const url = `${process.env.REACT_APP_BE_BASE_URL}/public/create-partial-subscriber`;
      const response = await axios.post<SubscriptionResponse>(url, payload, {
        headers,
      });

      console.log("Create Partial Subscriber Response:", response.data); // Debugging log

      setResponseMessage(response.data.message);
      setIdentifier(response.data.identifier); // Store the UUID from the response
      console.log("UUID:", response.data.identifier); // Debugging log
      console.log("Identifier state after submit:", response.data.identifier); // Log the identifier state

      console.log("API call successful", response.data); // Debugging log

      handleClose(); // close the first modal
      handleVerifyOpen(); // open the second modal
    } catch (error: any) {
      setResponseErrorMessage(error.response.data.message);
      console.log(`Here is the response error ${responseErrorMessage}`);

      console.error(
        "API call failed",
        error.response ? error.response.data : error,
      ); // Detailed error log
    }
  };

  const handleResendVerification = async () => {
    console.log("Resend verification code initiated"); // Debugging log
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-Shareduled-Platform": "PUBLIC",
      };

      console.log("Request Payload:", { email: formState.email }); // Debugging log
      console.log("Request Headers:", headers); // Debugging log

      const url = `${process.env.REACT_APP_BE_BASE_URL}/public/resend-verify-partial-subscriber-email-code`;
      const response = await axios.put<SubscriptionResponse>(
        url,
        { email: formState.email },
        { headers },
      );

      setResponseMessage(response.data.message);
      console.log("Resend verification code successful", response.data); // Debugging log

      if (response.data.identifier) {
        setIdentifier(response.data.identifier); // Store the UUID from the response
        console.log(
          "Identifier from resend verification:",
          response.data.identifier,
        ); // Debugging log
        console.log(
          "Identifier state after resend verification:",
          response.data.identifier,
        ); // Log the identifier state
      }

      // Start countdown
      setCountdown(300);
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error: any) {
      setResponseErrorMessage(
        error?.response?.data?.message ?? "Problem resending Code",
      );
      console.error(
        "Resend verification code failed",
        error.response ? error.response.data : error,
      ); // Detailed error log
    }
  };

  // Subscription plans list
  const subscriptionList: Plan[] = [
    {
      title: "Basic Monthly Plan",
      description: "Save up to 50% Monthly",
      price: "$69.50",
      discount: "$139",
      note: "*7 days free trial, payment card required",
      key: "Monthly",
      buttonText: "Get your 7 days free trial now",
    },
    {
      title: "Lifetime Access",
      description: "Enjoy up to 90% off (Save over $5,200!)",
      price: "$599",
      discount: "$5990",
      note: "*All existing and future updates for life, no free trials",
      key: "Lifetime",
      buttonText: "Get lifetime access now",
    },
    {
      title: "Basic Yearly Plan",
      description: "Save up to 50% Yearly",
      price: "$695",
      discount: "$1668",
      note: "*7 days free trial, payment card required",
      key: "Yearly",
      buttonText: "Get your 7 days free trial now",
    },
  ];

  // State variables for modal visibility
  const [open, setOpen] = React.useState(false);
  const [verifyOpen, setVerifyOpen] = React.useState(false);

  // Handlers to open/close modals
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleVerifyOpen = () => setVerifyOpen(true);
  const handleVerifyClose = () => setVerifyOpen(false);

  const [selectedPlan, setSelectedPlan] = React.useState<Plan>(
    subscriptionList[1],
  );

  const handleCardClick = (plan: Plan) => {
    setSelectedPlan(plan);
    setFormState((prev) => {
      return { ...prev, plan: plan.key };
    });
    setButtonText(plan.buttonText);
  };

  // Email validation regex
  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  // Function to validate email
  const validateEmail = () => {
    if (!emailRegex.test(formState.email)) {
      setFormState((prev) => ({
        ...prev,
        emailError: "Please enter a valid email address.",
      }));
    } else {
      setFormState((prev) => ({ ...prev, emailError: "" }));
    }
  };

  // Function to validate first name
  const validateFirstName = () => {
    if (formState.firstName.trim() === "") {
      setFormState((prev) => ({
        ...prev,
        firstNameError: "First name cannot be empty.",
      }));
    } else {
      setFormState((prev) => ({ ...prev, firstNameError: "" }));
    }
  };

  // Function to check if the form is valid
  const isFormValid = () => {
    return (
      !formState.emailError &&
      !formState.firstNameError &&
      formState.email &&
      formState.firstName &&
      formState.isAdult
    );
  };

  // Handler for proceeding with the form
  const handleProceed = async () => {
    if (isFormValid()) {
      console.log("Form is valid, proceeding to API call"); // Debugging log
      try {
        await handleSubmit({ preventDefault: () => {} } as React.FormEvent); // Trigger API call
      } catch (error: any) {
        setResponseErrorMessage(`Error: ${error.message}`);
        console.error(
          "API call failed",
          error.response ? error.response.data : error,
        );
      }
    } else {
      console.log("Form is invalid, cannot proceed"); // Debugging log
    }
  };

  // Handler for validating the verification code
  const handleValidate = async () => {
    if (formState.verificationCode.trim().length < 6) {
      setFormState((prev) => ({
        ...prev,
        verificationCodeError:
          "Verification code must be at least 6 characters long.",
      }));
      return; // Exit if the verification code is too short
    } else {
      setFormState((prev) => ({ ...prev, verificationCodeError: "" }));

      try {
        setIsLoading(true);

        console.log("TESTING NODE ENV: ", process.env.NODE_ENV);

        const payload = {
          identifier,
          code: formState.verificationCode,
          newPlan: formState.plan,
          successUrl: `${process.env.REACT_APP_FE_BASE_URL}/thank-you`,
        };

        const headers = {
          "Content-Type": "application/json",
          "X-Shareduled-Platform": "PUBLIC",
        };

        console.log("Request Payload:", payload); // Debugging log
        console.log("Request Headers:", headers); // Debugging log

        const url = `${process.env.REACT_APP_BE_BASE_URL}/public/get-subscription-link`;
        const response = await axios.post<SubscriptionResponse>(url, payload, {
          headers,
        });

        console.log("Get Subscription Link Response:", response.data); // Debugging log

        if (response.data.success) {
          window.location.href = response.data.link;
        } else {
          setResponseMessage(response.data.message);
        }
        console.log("API call successful", response.data); // Debugging log
      } catch (error: any) {
        setResponseMessage(`Error: ${error.message}`);
        setResponseErrorMessage(error.response.data.message);
        console.error(
          "API call failed",
          error.response ? error.response.data : error,
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    setFormState((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Style for the modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "35%",
    width: { xs: "90%", md: "35%" },
    bgcolor: "#F5F3F7",
    boxShadow: 24,
    p: "0 ",
    borderRadius: "10px",
  };

  return (
    <Box
      component="section"
      marginY={12}
      id="subscription"
      sx={{
        backgroundColor: { xs: "none", sm: "#FAF9FB" },
        p: { xs: "0", sm: "5rem 2rem" },
      }}
    >
      {/* Subscription Header */}
      <Typography
        variant="h6"
        component="h2"
        sx={{
          color: "#996000",
          fontWeight: "bold",
          marginTop: "6rem",
          textAlign: "center",
          backgroundColor: "#FFF6E5",
          width: "fit-content",
          margin: "auto",
          padding: "1.2rem 3rem",
          borderRadius: "1rem",
        }}
      >
        SUBSCRIPTION
      </Typography>

      {/* Subscription Plans Header */}
      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          marginY: "2rem",
        }}
      >
        Our Subscription Plans
      </Typography>

      {/* Subscription Plans List */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        {subscriptionList.map((subscription, index) => {
          const isActive = selectedPlan?.title === subscription.title;

          return (
            <Box
              component="div"
              key={index}
              onClick={() => handleCardClick(subscription)}
              sx={{
                border: "1px solid #8898AA",
                borderRadius: "20px",
                padding: "2rem",
                width: { xs: "100%", md: "auto" },
                backgroundColor: isActive ? "#550066" : "#ffffff",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              <Stack spacing={3}>
                <Typography
                  component="p"
                  sx={{
                    fontWeight: "500",
                    padding: "0.75rem 1.5rem",
                    backgroundColor: isActive ? "#FBE5FF" : "#FAF9FB",
                    width: "fit-content",
                    borderRadius: "15px",
                    color: isActive ? "#550066" : "#3A4450",
                  }}
                >
                  {subscription.description}
                </Typography>

                <Typography
                  component="p"
                  sx={{
                    fontWeight: "bold",
                    color: isActive ? "#FFD080" : "black",
                  }}
                >
                  {subscription.title}
                </Typography>

                <Typography
                  component="p"
                  sx={{
                    fontSize: "2rem", // Increase font size
                    display: "flex", // Align items horizontally
                    alignItems: "center",
                    gap: "0.5rem", // Add space between price and discount
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "2rem",
                      color: isActive ? "#FFD080" : "black",
                    }}
                  >
                    {subscription.price}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      textDecoration: "line-through",
                      fontSize: "2rem",
                      color: "#D2D9DF",
                    }}
                  >
                    ({subscription.discount})
                  </Box>
                </Typography>

                <Typography
                  sx={{
                    color: "#D2D9DF",
                  }}
                >
                  {subscription.note}
                </Typography>
              </Stack>
            </Box>
          );
        })}
      </Box>

      {/* Button Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{
            width: "26rem",
            marginBottom: "1rem",
            justifyItems: "center",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 0",
          }}
        >
          {buttonText}
        </Button>
      </Box>

      {/* Features List */}
      <Box
        sx={{
          marginTop: "3rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Features you get include:
        </Typography>
        <Typography
          component="ol"
          sx={{
            paddingLeft: 4,
            listStyleType: "decimal",
          }}
        >
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Automated appointment bookings:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Reduce no-shows and increase revenue with our automated booking
              system.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Automatic slot generation:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Save time and effort by letting the app create available slots for
              your business.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Secure payment processing:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Get paid on time, every time, with our secure payment processing
              system.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Real-time booking management:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Easily manage your bookings, staff, and services with our
              intuitive app.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Cancellation protection:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Receive 20% of the service cost as a cancellation fee if a user
              cancels last-minute.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Instant payouts:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Get paid quickly and easily, with funds transferred to your
              account immediately after a booking is completed.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Custom business website:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Share your booking page on social media or with clients, to
              increase visibility and showcase your staff, services, and
              address.
            </Box>
          </Typography>
        </Typography>
      </Box>

      {/* First Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              component="div"
              sx={{
                backgroundColor: "#ffffff",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              {responseErrorMessage && (
                <Alert
                  severity="error"
                  onClose={() => setResponseErrorMessage(null)}
                  sx={{
                    mb: 2,
                  }}
                >
                  {responseErrorMessage}
                </Alert>
              )}
              <Typography
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {selectedPlan?.title}
              </Typography>
            </Box>

            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ padding: "2rem" }}
            >
              <Typography
                variant="h6"
                component="h3"
                sx={{ fontWeight: "bold", marginBottom: "1rem" }}
              >
                Your Details
              </Typography>

              <Stack spacing={2}>
                <TextField
                  variant="outlined"
                  placeholder="Email Address"
                  fullWidth
                  name="email"
                  value={formState.email}
                  onChange={handleInputChange}
                  onBlur={validateEmail}
                  error={!!formState.emailError}
                  helperText={formState.emailError}
                  InputProps={{
                    startAdornment: (
                      <img
                        src={emailIcon}
                        alt="Email Icon"
                        style={{ marginRight: "0.5rem" }}
                      />
                    ),
                  }}
                />
                <TextField
                  variant="outlined"
                  placeholder="First Name"
                  fullWidth
                  name="firstName"
                  value={formState.firstName}
                  onChange={handleInputChange}
                  onBlur={validateFirstName}
                  error={!!formState.firstNameError}
                  helperText={formState.firstNameError}
                  InputProps={{
                    startAdornment: (
                      <img
                        src={firstnameIcon}
                        alt="First Name Icon"
                        style={{ marginRight: "0.5rem" }}
                      />
                    ),
                  }}
                />
                <TextField
                  variant="outlined"
                  placeholder="Country"
                  fullWidth
                  value="Canada"
                  InputProps={{
                    startAdornment: (
                      <img
                        src={countryIcon}
                        alt="Country Icon"
                        style={{ marginRight: "0.5rem" }}
                      />
                    ),
                    readOnly: true,
                  }}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    name="isAdult"
                    checked={formState.isAdult}
                    onChange={handleInputChange}
                  />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#8898AA",
                    }}
                  >
                    I am 18 and above
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  disabled={!isFormValid()}
                  onClick={handleProceed}
                  sx={{
                    width: "100%",
                    backgroundColor: isFormValid()
                      ? AppColours.mainPurple
                      : "gray",
                    color: AppColours.MainYellow,
                    padding: "1rem 0",
                    borderRadius: "10px",
                  }}
                >
                  Proceed
                </Button>

                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    marginTop: "1rem",
                    color: "#8898AA",
                    fontWeight: "bold",
                  }}
                >
                  Already started the process before?{" "}
                  <Typography
                    component="span"
                    sx={{
                      color: AppColours.mainPurple,
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      handleClose();
                      handleVerifyOpen();
                    }}
                  >
                    Verify
                  </Typography>
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* Second Modal */}
      <Modal
        open={verifyOpen}
        onClose={handleVerifyClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={verifyOpen}>
          <Box sx={style}>
            <Box
              component="div"
              sx={{
                backgroundColor: "#ffffff",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              {formState.verificationCodeError && (
                <Alert
                  severity="error"
                  onClose={() =>
                    setFormState((prev) => {
                      return { ...prev, verificationCodeError: "" };
                    })
                  }
                  sx={{
                    mb: 2,
                  }}
                >
                  {formState.verificationCodeError}
                </Alert>
              )}
              {responseErrorMessage && (
                <Alert
                  severity="error"
                  onClose={() => setResponseErrorMessage(null)}
                  sx={{
                    mb: 2,
                  }}
                >
                  {responseErrorMessage}
                </Alert>
              )}
              <Typography
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {selectedPlan?.title}
              </Typography>
            </Box>

            <Stack spacing={2} sx={{ padding: "2rem" }}>
              <Typography
                variant="h6"
                component="h3"
                sx={{ fontWeight: "bold", margin: "1rem", marginBottom: "0" }}
              >
                Verify
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Email Address"
                fullWidth
                name="email"
                value={formState.email}
                onChange={handleInputChange}
                onBlur={validateEmail}
                error={!!formState.emailError}
                helperText={formState.emailError}
                InputProps={{
                  startAdornment: (
                    <img
                      src={emailIcon}
                      alt="Email Icon"
                      style={{ marginRight: "0.5rem" }}
                    />
                  ),
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Typography
                  sx={{
                    color: AppColours.mainPurple,
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  onClick={handleResendVerification}
                >
                  Resend verification code
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: AppColours.mainPurple,
                  }}
                >
                  ({Math.floor(countdown / 60)}:
                  {(countdown % 60).toString().padStart(2, "0")})
                </Typography>
              </Box>
              <TextField
                variant="outlined"
                placeholder="Verification Code"
                fullWidth
                name="verificationCode"
                value={formState.verificationCode}
                onChange={handleInputChange}
                error={!!formState.verificationCodeError}
                helperText={formState.verificationCodeError}
                InputProps={{
                  startAdornment: (
                    <img
                      src={code}
                      alt="lock Icon"
                      style={{ marginRight: "0.5rem" }}
                    />
                  ),
                }}
              />
              <Button
                variant="contained"
                disabled={isLoading || !identifier}
                onClick={() => {
                  console.log("Identifier before sending request:", identifier); // Log the identifier
                  handleValidate();
                }}
                sx={{
                  width: "100%",
                  backgroundColor: AppColours.mainPurple,
                  color: AppColours.MainYellow,
                  padding: "1rem 0",
                  borderRadius: "10px",
                }}
              >
                Validate
              </Button>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  marginTop: "1rem",
                  fontWeight: "bold",
                  color: "#8898AA",
                }}
              >
                You are new to this process?{" "}
                <Typography
                  component="span"
                  sx={{
                    color: AppColours.mainPurple,
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    handleVerifyClose();
                    handleOpen();
                  }}
                >
                  Start Process
                </Typography>
              </Typography>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default CheckoutPricing;
