import React from "react";
import LayoutV2 from "../layout/LayoutV2";
import Hero from "../new-landing/Hero";
import { Container } from "@mui/material";
import Pricing from "../new-landing/Pricing";
import ServicesForBusiness from "../new-landing/ServicesForBusiness";
import ServicesForUsers from "../new-landing/ServicesForUsers";
import GetStarted from "../new-landing/GetStarted";
import NewsLetter from "../new-landing/NewsLetter";
import Faq from "../new-landing/Faq";

const NewLandingPage = () => {
  return (
    <LayoutV2
      child={
        <React.Fragment>
          <Container
            sx={{
              padding: "3rem",
            }}
          >
            <Hero />
            <ServicesForBusiness />
            <ServicesForUsers />
            <Pricing />
            <GetStarted />
            <NewsLetter />
            <Faq />
          </Container>
        </React.Fragment>
      }
    />
  );
};

export default NewLandingPage;
