import { Box, Stack, Typography } from "@mui/material";

const ThankYouHero = () => {
  return (
    <Box
      component="section"
      sx={{
        textAlign: "center",
      }}
    >
      <Stack spacing={3}>
        <Typography
          variant="h4"
          component="h3"
          sx={{
            fontWeight: "bold",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          Thank You for Signing Up On{" "}
          <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
            Shareduled!
          </Box>{" "}
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          Now that you've subscribed to this package…
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          You're just a few steps away from enjoying real-time appointment
          bookings, seamless business flow and recurring high-ticket clients
          like other business owners!
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          But you need to take just 2 more steps to “seal the deal”
        </Typography>
      </Stack>
    </Box>
  );
};

export default ThankYouHero;
