import { Box, Typography } from "@mui/material";
import personalized from "../../assets/new-landing/personalized.png";
import realTime from "../../assets/new-landing/real-time.png";
import reminder from "../../assets/new-landing/reminder.png";
import secure from "../../assets/new-landing/secure.png";
import paid from "../../assets/new-landing/paid.png";
import smilingOwner from "../../assets/new-landing/smiling-owner.png";

const ServicesForUsers = () => {
  const cardItems = [
    {
      icon: { paid },
      body: "Get paid for completed appointments",
    },
    {
      icon: { realTime },
      body: "Real-time availability checking",
    },
    {
      icon: { reminder },
      body: "Automated reminders and notifications",
    },
    {
      icon: { secure },
      body: "Secure payment processing",
    },
    {
      icon: { personalized },
      body: "Rate Business and Services",
    },
  ];

  return (
    <Box
      component="section"
      marginY={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: { xs: "auto", md: "650px" },
        backgroundImage: `url(${smilingOwner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        position: "relative",
        p: { xs: 2.5, md: 4 },
        pt: { xs: 4, md: 4 },
        pb: { xs: 4, md: 4 },
        borderRadius: 10,
      }}
    >
      {/* Overlay to darken background */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          bgcolor: "rgba(0, 0, 0, 0.6)",
          zIndex: 1,
          borderRadius: 10,
        }}
      />

      <Box
        sx={{
          zIndex: 2,
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{
            color: "#996000",
            fontWeight: "bold",
            marginY: "6rem",
            textAlign: "center",
            backgroundColor: "#FFF6E5",
            width: "fit-content",
            margin: "auto",
            padding: "1.2rem 3rem",
            borderRadius: "1rem",
          }}
        >
          FEATURES
        </Typography>

        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            marginY: "3rem",
          }}
        >
          Services For Users
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            flexDirection: { xs: "column", md: "row" },
            flexWrap: { xs: "nowrap", md: "wrap" },
          }}
        >
          {cardItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                p: { xs: "1rem 0.5rem", sm: "2rem 1rem" },
                border: "1px solid #C8C8C8",
                borderRadius: "20px",
                backdropFilter: "blur(10px)",
                width: { xs: "100%", md: "auto" },
              }}
            >
              <Box
                component="img"
                src={Object.values(item.icon)[0]}
                alt={item.body}
                loading="lazy"
                sx={{
                  marginBottom: "1rem",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "semibold",
                }}
              >
                {item.body}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* The Old one */}

      {/* <Grid
        container
        spacing={5}
        marginTop={5}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{
                  width: "8rem",
                }}
                src={calender}
                alt="Personalized calender icon"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Personalized calendars and scheduling
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{
                  objectFit: "cover",
                  width: "8rem",
                }}
                image={realTime}
                alt="Real-time availability icon"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Real-time availability checking
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
            elevation={2}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{ width: "8rem" }}
                image={scheduling}
                alt="personalized scheduling image"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Personalized calendars and scheduling
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
            elevation={2}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{ width: "8rem" }}
                image={reminder}
                alt="Automated Reminder image"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Turn your feedback to features that matter
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
            elevation={2}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{ width: "8rem" }}
                image={workflows}
                alt="customizable workflow icon"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Customizable workflows and permissions
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default ServicesForUsers;
