import LayoutV2 from "../layout/LayoutV2";
import BusinessGuide from "../new-landing/BusinessGuide";

const BusinessGuideScreen = () => {
  return (
    <LayoutV2
      hideFooterNav
      hideFooterResources
      hideNavLinks
      child={<BusinessGuide />}
    />
  );
};

export default BusinessGuideScreen;
