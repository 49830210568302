import { Alert, Box, Typography } from "@mui/material";
import { AppColours } from "../../helpers/colors";
import React, { useState } from "react";
import axios from "axios";

// defining interface for ask a question payload
interface ServiceRequestPayload {
  first_name: string;
  email: string;
  phone_number: string;
  service_requested: string;
  message: string;
}

interface ServiceRequestResponse {
  success: boolean;
  existing: boolean;
  message: string;
}

const NewsLetter = () => {
  const [newsletterEmail, setNewsletterEmail] = React.useState<string>("");
  const [status, setStatus] = React.useState<string | null>(null);

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [serviceSuccessMsg, setServiceSuccessMsg] = useState("");
  const [serviceErrorMsg, setServiceErrorMsg] = useState("");

  const newsLetterSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const url = `${process.env.REACT_APP_BE_BASE_URL}/public/add-to-newsletter`;
      const payload = { email: newsletterEmail };

      const headers = {
        "Content-Type": "application/json",
        "X-Shareduled-Platform": "PUBLIC",
      };

      const response = await axios.post(url, payload, { headers });

      if (response.data) {
        setSuccessMsg(`${response.data.message}`);
        setStatus(`Success: ${response.data.message}`);
      } else {
        setStatus("Success: Newsletter sent, but no message was provided.");
        setSuccessMsg("Newsletter subscription successful");
      }

      setNewsletterEmail("");
    } catch (error: any) {
      setStatus(`Error: ${error.response?.data?.message || error.message}`);
      console.log(error.response ? error.response.data : error.message);
      setErrorMsg(
        `${error.response?.data?.message ?? error.message ?? "Error subscribing to newsletter"}`,
      );
    }
  };

  const submitServiceRequest = async (
    payload: ServiceRequestPayload,
    setServiceStatus: React.Dispatch<React.SetStateAction<string | null>>,
  ) => {
    try {
      const url = `${process.env.REACT_APP_BE_BASE_URL}/public/ask-shareduled-question`;

      const headers = {
        "Content-Type": "application/json",
        "X-Shareduled-Platform": "PUBLIC",
      };

      const response = await axios.post<ServiceRequestResponse>(url, payload, {
        headers,
      });
      console.log(response);

      if (response.data) {
        console.log("Response: ", response.data.message);
        setServiceSuccessMsg(`${response.data.message}`);

        return response.data;
      } else {
        console.warn("Unexpected response:", response.data);
        return null;
      }
    } catch (error: any) {
      console.error("Error: ", error);
      setServiceStatus(
        `Error: ${error.response?.data?.message || error.message}`,
      );
      setServiceErrorMsg(
        `${error.response?.data?.message ?? error.message ?? "Error subscribing to newsletter"}`,
      );
    }
  };

  React.useEffect(() => {
    if (status) console.log(status);
  }, [status]);

  const [serviceRequest, setServiceRequest] =
    React.useState<ServiceRequestPayload>({
      first_name: "",
      email: "",
      phone_number: "",
      service_requested: "",
      message: "",
    });

  const [serviceStatus, setServiceStatus] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setServiceRequest((prev) => ({ ...prev, [name]: value }));
  };

  const handleServiceSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await submitServiceRequest(
        serviceRequest,
        setServiceStatus,
      );
      if (response) {
        setServiceStatus(`Success: ${response.message}`);
      } else {
        setServiceStatus(
          "Success: Service request sent, but no message was provided.",
        );
      }

      setServiceRequest({
        first_name: "",
        email: "",
        phone_number: "",
        service_requested: "",
        message: "",
      });
    } catch (error: any) {
      setServiceStatus(
        `Error: ${error.response?.data?.message || error.message}`,
      );
    }
  };

  React.useEffect(() => {
    if (serviceStatus) console.log(serviceStatus);
  }, [serviceStatus]);

  return (
    <Box
      component="section"
      marginTop={8}
      id="questions"
      sx={{
        backgroundColor: { xs: "none", sm: "#FFFBF5" },
        height: "auto",
        p: { xs: "0", sm: "4rem 2rem" },
      }}
    >
      {successMsg && (
        <Alert
          severity="success"
          onClose={() => setSuccessMsg("")}
          sx={{
            mb: 2,
          }}
        >
          {successMsg}
        </Alert>
      )}
      {errorMsg && (
        <Alert
          severity="error"
          onClose={() => setErrorMsg("")}
          sx={{
            mb: 2,
          }}
        >
          {errorMsg}
        </Alert>
      )}
      <Typography
        variant="h4"
        component="h3"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          marginBottom: "2rem",
        }}
      >
        Want To Join Our Community Of Business Owners Like You? Join Our
        Newsletter
      </Typography>

      {/* Newsletter */}
      <Box
        component="div"
        sx={{
          backgroundColor: AppColours.mainPurple,
          padding: "4rem 0",
          width: "100%",
        }}
      >
        <Box
          component="form"
          onSubmit={newsLetterSubmit}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            backgroundColor: AppColours.mainPurple,
            padding: { xs: "0 1rem", sm: "0 3rem" },
          }}
        >
          <Box
            component="input"
            type="email"
            value={newsletterEmail}
            onChange={(e) => setNewsletterEmail(e.target.value)}
            placeholder="Your Business/Personal Email Here"
            sx={{
              width: { xs: "100%", sm: "35%" },
              padding: "1rem",
              borderBottom: "1px solid #EFCE95",
              marginBottom: { xs: "1rem", sm: "0" },
              backgroundColor: "transparent",
              outline: "none",
              color: "white",
              "::placeholder": {
                color: "white",
                fontSize: { xs: "0.8rem", sm: "1rem" },
              },
            }}
          ></Box>
          <Box
            component="button"
            type="submit"
            sx={{
              width: { xs: "100%", sm: "19rem" },
              padding: "1rem",
              borderRadius: "10px",
              border: "none",
              backgroundColor: AppColours.LightPurple,
              color: AppColours.mainPurple,
              cursor: "pointer",
            }}
          >
            JOIN NEWSLETTER
          </Box>
        </Box>
      </Box>

      {/* Special Request */}
      {serviceSuccessMsg && (
        <Alert
          severity="success"
          onClose={() => setServiceSuccessMsg("")}
          sx={{
            mt: 3,
            mb: 2,
          }}
        >
          {serviceSuccessMsg}
        </Alert>
      )}
      {serviceErrorMsg && (
        <Alert
          severity="error"
          onClose={() => setServiceErrorMsg("")}
          sx={{
            mt: 3,
            mb: 2,
          }}
        >
          {serviceErrorMsg}
        </Alert>
      )}
      <Typography
        variant="h4"
        component="h3"
        id="questions"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "4rem",
        }}
      >
        Have Any Questions For Us?
      </Typography>

      <Typography
        variant="h6"
        component="p"
        sx={{
          textAlign: "center",
          color: "#8898AA",
          marginBottom: "1rem",
        }}
      >
        Do you have any special requests for us? You can send an email or fill
        the form below:
      </Typography>

      <Box
        component="div"
        sx={{
          backgroundColor: AppColours.mainPurple,
          padding: "4rem 0",
        }}
      >
        <Box
          component="form"
          onSubmit={handleServiceSubmit}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            backgroundColor: AppColours.mainPurple,
            padding: { xs: "0 1rem", sm: "0 3rem" },
            width: "100%",
            gap: "5rem",
          }}
        >
          {/* Left column -> inputs */}
          <Box
            component="div"
            sx={{
              width: { xs: "100%", sm: "50%" },
            }}
          >
            <Box
              component="input"
              type="text"
              name="first_name"
              placeholder="Your First Name"
              value={serviceRequest.first_name}
              onChange={handleChange}
              sx={{
                width: "100%",
                padding: "1rem",
                borderBottom: "1px solid #EFCE95",
                marginBottom: { xs: "1rem", sm: "0" },
                backgroundColor: "transparent",
                outline: "none",
                color: "white",
                "::placeholder": {
                  color: "white",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                },
              }}
            />
            <Box
              component="input"
              type="email"
              name="email"
              placeholder="Your Email Here"
              value={serviceRequest.email}
              onChange={handleChange}
              sx={{
                width: "100%",
                padding: "1rem",
                borderBottom: "1px solid #EFCE95",
                marginBottom: { xs: "1rem", sm: "0" },
                marginTop: { xs: "0", sm: "3rem" },
                backgroundColor: "transparent",
                outline: "none",
                color: "white",
                "::placeholder": {
                  color: "white",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                },
              }}
            />
            <Box
              component="input"
              type="text"
              name="phone_number"
              placeholder="Your Canada Number"
              value={serviceRequest.phone_number}
              onChange={handleChange}
              sx={{
                width: "100%",
                padding: "1rem",
                borderBottom: "1px solid #EFCE95",
                marginBottom: { xs: "1rem", sm: "0" },
                marginTop: { xs: "0", sm: "3rem" },
                backgroundColor: "transparent",
                outline: "none",
                color: "white",
                "::placeholder": {
                  color: "white",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                },
              }}
            />
            <Box
              component="input"
              type="text"
              name="service_requested"
              value={serviceRequest.service_requested}
              onChange={handleChange}
              placeholder="What Service You Want"
              sx={{
                width: "100%",
                padding: "1rem",
                borderBottom: "1px solid #EFCE95",
                marginBottom: { xs: "1rem", sm: "0" },
                marginTop: { xs: "0", sm: "3rem" },
                backgroundColor: "transparent",
                outline: "none",
                color: "white",
                "::placeholder": {
                  color: "white",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                },
              }}
            />
          </Box>

          {/* Right column -> textarea */}
          <Box
            component="div"
            sx={{
              width: { xs: "100%", sm: "50%" },
            }}
          >
            <Box
              component="textarea"
              placeholder="Your Message Here"
              name="message"
              value={serviceRequest.message}
              onChange={handleChange}
              sx={{
                width: "100%",
                height: "15rem",
                padding: "1rem",
                border: "1px solid #EFCE95",
                marginBottom: { xs: "1rem", sm: "0" },
                backgroundColor: "transparent",
                outline: "none",
                color: "white",
                "::placeholder": {
                  color: "white",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                },
              }}
            ></Box>
            <Box
              component="button"
              type="submit"
              sx={{
                width: "100%",
                padding: "1.5rem",
                borderRadius: "10px",
                border: "none",
                backgroundColor: AppColours.LightPurple,
                color: AppColours.mainPurple,
                cursor: "pointer",
                marginTop: "3rem",
              }}
            >
              SEND NOW
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsLetter;
