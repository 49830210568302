import "tailwindcss/tailwind.css"; // Ensure Tailwind is loaded after Material UI

import React from "react";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LifetimeAccessScreen from "./components/screens/LifetimeAccessScreen";
import LeadMagnetScreen from "./components/screens/LeadMagnetScreen";
import NewLandingPage from "./components/screens/NewLandingPage";
import Blog from "./components/screens/Blog";
import ArticleDetails from "./components/blog/ArticleDetails";
import CheckoutScreen from "./components/screens/CheckoutScreen";
import ThankYouScreen from "./components/screens/ThankYouScreen";
import BusinessGuideScreen from "./components/screens/BusinessGuideScreen";
import UserGuideScreen from "./components/screens/UserGuideScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <NewLandingPage />,
    // Redirecting all users to homepage for now as it is under construction
    errorElement: <NewLandingPage />,
  },
  {
    path: "/home",
    element: <NewLandingPage />,
    // Redirecting all users to homepage for now as it is under construction
    errorElement: <NewLandingPage />,
  },
  {
    path: "/business-guide",
    element: <BusinessGuideScreen />,
  },
  {
    path: "/user-guide",
    element: <UserGuideScreen />,
  },
  {
    path: "/checkout",
    element: <CheckoutScreen />,
  },
  {
    path: "/thank-you",
    element: <ThankYouScreen />,
  },
  {
    path: "/lifetime-access",
    element: <LifetimeAccessScreen />,
  },
  {
    path: "/welcome",
    element: <LeadMagnetScreen />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog/:title",
    element: <ArticleDetails />,
  },
]);

let theme = createTheme({
  typography: {
    fontFamily: ["Kanit"].join(","),
    fontSize: 16,
  },
  palette: {
    primary: {
      main: "#550066",
    },
    secondary: {
      main: "#FFA000",
      light: "#FFD080",
    },
    info: {
      main: "#0B0D0F",
    },
    success: {
      main: "#009900",
    },
    error: {
      main: "#FF0000",
    },
    warning: {
      main: "#FFFF00",
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
