import React from "react";
import LayoutV2 from "../layout/LayoutV2";
import { Container } from "@mui/material";
import ThankYouHero from "../new-landing/ThankYouHero";
import Community from "../new-landing/Community";
import Devices from "../new-landing/Devices";
import WhatNext from "../new-landing/WhatNext";

const ThankYouScreen = () => {
  return (
    <LayoutV2
      hideFooterNav
      hideFooterResources
      hideNavLinks
      child={
        <React.Fragment>
          <Container
            sx={{
              padding: "3rem",
            }}
          >
            <ThankYouHero />
            <Community />
            <Devices />
            <WhatNext />
          </Container>
        </React.Fragment>
      }
    />
  );
};

export default ThankYouScreen;
