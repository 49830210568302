import { Box, Button, Stack, Typography } from "@mui/material";
import { AppColours } from "../../helpers/colors";
import facebookCummunity from "../../assets/new-landing/facebook-community.png";

const Community = () => {
  return (
    <Box
      component="section"
      marginTop={6}
      sx={{
        textAlign: "center",
      }}
    >
      <Typography
        variant="h6"
        component="h2"
        sx={{
          color: "#996000",
          fontWeight: "bold",
          marginTop: "6rem",
          textAlign: "center",
          backgroundColor: "#FFF6E5",
          width: "fit-content",
          margin: "auto",
          padding: "1.2rem 3rem",
          borderRadius: "1rem",
        }}
      >
        STEP ONE
      </Typography>

      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          marginY: "2rem",
        }}
      >
        Get Started with Our Community!
      </Typography>

      <Stack spacing={4} marginY={3}>
        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          Now that you've subscribed to this package…
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          You're just a few steps away from enjoying real-time appointment
          bookings, seamless business flow and recurring high-ticket clients
          like other business owners!
        </Typography>

        <Typography
          sx={{
            color: "#8898AA",
            textAlign: { xs: "left", md: "center" },
          }}
        >
          But you need to take just 2 more steps to “seal the deal”
        </Typography>
      </Stack>
      <Box
        component="img"
        loading="lazy"
        src={facebookCummunity}
        alt="A business owner relaxing"
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <Button
          variant="contained"
          href="https://www.facebook.com/groups/sharedulers"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            width: "26rem",
            marginBottom: "1rem",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 8px",
          }}
        >
          I WANT TO JOIN THE FACEBOOK COMMUNITY
        </Button>
      </Box>
    </Box>
  );
};

export default Community;
