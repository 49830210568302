import { Box, Typography } from "@mui/material";
import * as React from "react";
import Accordion, {
  AccordionSlots,
  accordionClasses,
} from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails, {
  accordionDetailsClasses,
} from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import faqData from "../lib/faqdata.json";

const Faq = () => {
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleExpansion = (index: number) => {
    setExpanded((prevExpanded) => (prevExpanded === index ? false : index));
  };

  return (
    <Box>
      <Box component="section" marginTop={10} id="faq">
        <Typography
          variant="h6"
          component="h2"
          sx={{
            color: "#996000",
            fontWeight: "bold",
            marginTop: "6rem",
            textAlign: "center",
            backgroundColor: "#FFF6E5",
            width: "fit-content",
            margin: "auto",
            padding: "1.2rem 3rem",
            borderRadius: "1rem",
          }}
        >
          FAQ
        </Typography>

        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            marginY: "2rem",
          }}
        >
          Got Questions? Let's Clear It Up
        </Typography>
      </Box>

      <Box component="div" border="none">
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={() => handleExpansion(index)}
            slots={{ transition: Fade as AccordionSlots["transition"] }}
            slotProps={{ transition: { timeout: 400 } }}
            sx={[
              expanded === index
                ? {
                    [`& .${accordionClasses.region}`]: {
                      height: "auto",
                    },
                    [`& .${accordionDetailsClasses.root}`]: {
                      display: "block",
                    },
                    border: "2px solid #550066",
                  }
                : {
                    [`& .${accordionClasses.region}`]: {
                      height: 0,
                    },
                    [`& .${accordionDetailsClasses.root}`]: {
                      display: "none",
                    },
                  },
              {
                borderRadius: "20px",
                marginBottom: "1rem",
                "&:focus": {
                  borderColor: "#550066",
                },
              },
            ]}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    backgroundColor: expanded === index ? "#550066" : "inherit",
                    borderRadius: "50%",
                    color: expanded === index ? "#FFF" : "inherit",
                  }}
                />
              }
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                borderRadius: "10px",
                paddingY: expanded === index ? "0.5rem" : "1.5rem",
              }}
            >
              <Typography variant="h6" component="h3">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                component="p"
                sx={{ color: "#666", textAlign: "justify" }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default Faq;
