import { Box, Button, Grid, Typography } from "@mui/material";
import { AppColours } from "../../helpers/colors";
import { Link, useNavigate } from "react-router-dom";
import feature1 from "../../assets/new-landing/Feature-1.gif";
import feature2 from "../../assets/new-landing/Feature-2.gif";
import feature3 from "../../assets/new-landing/Feature-3.gif";
import feature4 from "../../assets/new-landing/Feature-4.gif";

const ServicesForBusiness = () => {
  // Use the navigate hook to navigate to the checkout page
  const navigate = useNavigate();
  const handleButtonRoute = (): void => {
    navigate("/checkout");
  };

  return (
    <Box component="section" marginTop={16} id="features">
      <Typography
        variant="h6"
        component="h2"
        sx={{
          color: "#996000",
          fontWeight: "bold",
          marginY: "6rem",
          textAlign: "center",
          backgroundColor: "#FFF6E5",
          width: "fit-content",
          margin: "auto",
          padding: "1.2rem 3rem",
          borderRadius: "1rem",
        }}
      >
        FEATURES
      </Typography>

      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          marginY: "3rem",
        }}
      >
        Services For Business Owners
      </Typography>

      {/* gifs with features section */}

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "0.5px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FBE5FF, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={feature1}
              loading="lazy"
              alt="Scheduling and Booking Management GIF"
              sx={{
                // border: "0.05px solid #550066",
                borderRadius: "2rem",
              }}
            />
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Feature 1
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Scheduling and Booking Management
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Shareduled allows businesses to manage scheduling, bookings, and
              appointments efficiently, reducing no-shows and streamlining
              operations.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "1px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FFF6E5, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Feature 2
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Resource Sharing and Management
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Businesses have access to a wallet where appointment bills are
              automatically paid and withdrawn into a linked account, and they
              can also view all wallet transactions and paid-out funds.
            </Typography>
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={feature2}
              loading="lazy"
              alt="Resource Sharing and Management gif"
              sx={{
                // border: "1px solid #996000",
                borderRadius: "20px",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "1px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FBE5FF, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={feature3}
              loading="lazy"
              alt="Staff and Customer Management"
              sx={{
                // border: "1px solid #550066",
                borderRadius: "20px",
              }}
            />
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Feature 3
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Staff and Customer Management
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Shareduled provides tools to add staff, enabling businesses to
              track staff appointments, approve staff unavailability, and manage
              customer appointments by starting and ending both customer and
              staff appointments, ensuring complete control over business
              operations.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "1px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FFF6E5, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Feature 4
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Reporting and Analytics
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Shareduled offers reporting and analytics tools to help businesses
              track performance, identify trends, and make data-driven
              decisions.
            </Typography>
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={feature4}
              loading="lazy"
              alt="Reporting and Analytics gif"
              sx={{
                // border: "1px solid #996000",
                borderRadius: "20px",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Button and Link Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={handleButtonRoute}
          sx={{
            width: "26rem",
            justifyItems: "center",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 0",
          }}
        >
          I WANT TO SIGN-UP FOR FREE
        </Button>
        <br />
      </Box>
      <Link
        to="/checkout"
        style={{
          fontWeight: "bold",
          fontSize: "10px",
          textAlign: "center",
          display: "block",
          marginTop: "1rem",
        }}
      >
        *7 DAY FREE TRIAL-CREDIT CARD REQUIRED
      </Link>
    </Box>
  );
};

export default ServicesForBusiness;
