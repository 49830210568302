import { Box, Container, Grid, Typography } from "@mui/material";
import { AppColours } from "../../helpers/colors";
// import { useNavigate } from "react-router-dom";
import user1 from "../../assets/new-landing/User-1.png";
import user2 from "../../assets/new-landing/User-2.png";
import user3 from "../../assets/new-landing/User-3.png";

const UserGuide = () => {
  // const navigate = useNavigate();
  return (
    <Container>
      <Typography
        variant="h3"
        component="h1"
        sx={{
          textAlign: { xs: "left", md: "center" },
          fontWeight: "bold",
          marginY: { xs: 6, md: 8 },
        }}
      >
        You: “How Many Steps Will It Take To Create An Account With{" "}
        <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
          “Shareduled?”
        </Box>{" "}
        <br /> <br /> Answer: “It Takes Just Few Simple Steps!” See below
      </Typography>

      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: "bold",
          textAlign: { xs: "left", md: "center" },
          marginY: { xs: "2rem", md: "3rem" },
        }}
      >
        User Setup Guide
      </Typography>

      {/* image with features section */}

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "0.5px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FBE5FF, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={user1}
              alt="Scheduling and Booking Management GIF"
              sx={{
                borderRadius: "2rem",
              }}
            />
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Step 1
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Sign Up
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Download and install the “Shareduled” App (Andriod and iOS)
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "1px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FFF6E5, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Step 2
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Set Up Your Profile
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Use the easy to navigate interface to set up your account in less
              than 3 minutes.
            </Typography>
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={user2}
              alt="Resource Sharing and Management gif"
              sx={{
                // border: "1px solid #996000",
                borderRadius: "20px",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "1px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FBE5FF, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={user3}
              alt="Staff and Customer Management"
              sx={{
                // border: "1px solid #550066",
                borderRadius: "20px",
              }}
            />
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Step 3
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Enjoy Seamless Scheduling Experience
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              You can book the very first service you need immediately!
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: "bold",
          textAlign: { xs: "left", md: "center" },
          marginY: "3rem",
        }}
      >
        Ready to get started today?
      </Typography>

      <Typography
        component="p"
        sx={{
          textAlign: { xs: "left", md: "center" },
          marginY: "3rem",
          color: "#00000080",
          fontWeight: "bold",
        }}
      >
        Visit Google Play Store or Apple App Store, search "Shareduled", and
        download our app today!
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "8rem",
        }}
      >
        {/* <Button
          variant="contained"
          onClick={() => {
            navigate("/checkout");
          }}
          sx={{
            width: "26rem",
            justifyItems: "center",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 0",
          }}
        >
          I WANT TO CREATE MY ACCOUNT
        </Button> */}
        <br />
      </Box>
    </Container>
  );
};

export default UserGuide;
